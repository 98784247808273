import './App.css';
import TicTacToe from './components/TicTacToe';

function App() {
  return (
    <TicTacToe/>
  );
}

export default App;
